<template lang="pug">
v-dialog(:value="value" @input="$emit('input',$event)"
    fullscreen
    hide-overlay
    transition="dialog-top-transition")
    v-card
      v-card-title.justify-space-between
        div Прайс Лист
        v-btn(icon dark @click="$emit('input',false)"): v-icon mdi-close
      v-card
        v-list
          template(v-for="(c,index) in categories")
            v-list-item(Zthree-line)
              v-list-item-avatar(rounded)
                v-icon.grey(light) {{c.icon}}
              v-list-item-content
                v-list-item-title {{c.title}}
                v-list-item-subtitle Subtitle
              v-list-item-action
                v-btn(icon): v-icon(color="grey lighten-1") mdi-menu-right
            v-divider(v-if="index < categories.length - 1" :key="index")
        v-card-actions
          v-btn(color="primary" outlined x-large block depressed) Загрузить Прайс Лист
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {},

  // mixins: [decimalMixin, dictionaryMixin, billsAndCoinsMixin],

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    showDialog: false,
    editMode: false,
    discountMarkupValue: 0,
    positionsDialog: false,
  }),

  computed: {
    ...mapState({
      categories: (state) => state.tjtheme.categories,
    }),
  },

  watch: {},

  methods: {
    ...mapActions({
    }),
  },
}
</script>
<style lang="stylus"></style>
